<template>
  <div class="layout-wrapper layout-1">
    <div class="layout-inner">
      <div class="layout-container">
        <app-layout-sidenav />

        <div class="layout-content">
          <app-layout-navbar :title="title"/>
          <div class="router-transitions container-fluid1 d-flex1 align-items-stretch flex-grow-1 p-10a">
            <router-view v-on:update-title="updatePageTitle"/>
          </div>

          <app-layout-footer />
        </div>
      </div>
    </div>
    <div class="layout-overlay" @click="closeSidenav"></div>
  </div>
</template>

<script>
import LayoutNavbar from './UI2Navbar'
import LayoutSidenav from './UI2Sidebar'
import LayoutFooter from './LayoutFooter'

export default {
  name: 'ui2-layout',
  components: {
    'app-layout-navbar': LayoutNavbar,
    'app-layout-sidenav': LayoutSidenav,
    'app-layout-footer': LayoutFooter
  },

  data () {
    return {
      title: 'comenius studio 2'
    }
  },

  mounted () {
    this.layoutHelpers.init()
    this.layoutHelpers.update()
    this.layoutHelpers.setAutoUpdate(true)
  },

  beforeDestroy () {
    this.layoutHelpers.destroy()
  },

  methods: {
    updatePageTitle: function (title) {
      this.title = title
    },
    closeSidenav () {
      this.layoutHelpers.setCollapsed(true)
    }
  }
}
</script>
