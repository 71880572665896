<template>
    <div>
        <b-tabs small class="nav-tabs-top" nav-wrapper-class="nav-sm">
            <b-tab active >
                <template #title>
                    <i class="ion ion-ios-albums"></i> Inspector
                </template>
                <div class="card-body p-0">
                    <div class="flex-col hidden mr-2 sm:flex mt-2 h-screen90 p-6a overflow-y-scroll scrollbar w-96">
                
                        <div class=" px-2  space-y-3 w-full ">
                           
                            <div class="relative">
                                
                                <div @click="addNewPage(-1)" class="overflow-hidden rounded-md w-full h-24 transition duration-200 transform scale-100 bg-white border border-gray-300 shadow-sm scale hover:scale-95">
                                    <span class="text-dark img-thumbnail-content display-4  opacity-100">
                                        <i class="ion ion-ios-add-circle-outline"></i>
                                    </span>
                                </div>
                            </div>
                    
                            <div class="pb-10"></div>
                    
                        </div>
                    </div>
                </div>
            </b-tab>
            <b-tab  >
                <template #title>
                    <i class="ion ion-ios-albums"></i> Gallery
                </template>
                <div class="card-body p-0">
                    <div class="flex-col hidden mr-2 sm:flex mt-2 h-screen90 p-6a overflow-y-scroll scrollbar w-96">
                
                        <div class=" px-2  space-y-3 w-full ">
                            <div class="relative" v-for="(p,i) in pages" :key="i">
                                <div class="overflow-hidden rounded-md w-full h-24 transition duration-200 transform scale-100 bg-white border border-gray-300 shadow-sm scale hover:scale-95">
                                    <img :src="p.thumb" alt="" class="absolute top-0">
                                </div>
                            </div>
                            <div class="relative">
                                
                                <div @click="addNewPage(-1)" class="overflow-hidden rounded-md w-full h-24 transition duration-200 transform scale-100 bg-white border border-gray-300 shadow-sm scale hover:scale-95">
                                    <span class="text-dark img-thumbnail-content display-4  opacity-100">
                                        <i class="ion ion-ios-add-circle-outline"></i>
                                    </span>
                                </div>
                            </div>
                    
                            <div class="pb-10"></div>
                    
                        </div>
                    </div>
                </div>
            </b-tab>
        </b-tabs>
    </div>
</template>
<style>


</style>
<script>
// import ActivityToolbar from './Partials/ActivityToolbar'

export default {
    name: 'templates',
    components: {
        
    },
    mounted() {
        this.init()
    },
    data() {
        return {
            title: 'Draft',
            pages: [
                { thumb: '/img/bg/1.jpg' },
                { thumb: '/img/bg/2.jpg' },
                { thumb: '/img/bg/3.jpg' },
                { thumb: '/img/bg/4.jpg' },
                { thumb: '/img/bg/5.jpg' },
                { thumb: '/img/bg/6.jpg' },
                { thumb: '/img/bg/7.jpg' },
                { thumb: '/img/bg/8.jpg' },
            ],
            sortBy: 'Popular',
            curTag: 'all',
            allTemplates: [
                { thumb: '', title: 'Blank template', slug: "blank", author: { name: 'Comenius', thumb: '/img/avatars/1-small.png' }, public: true, likes: 1, views: 1, liked: false, collections: [] },
                { thumb: '/img/bg/9.jpg', slug: 'quiz-1', title: 'Interactive Quizz', icon: "ion ion-ios-cube", category: 'simulation', author: { name: 'Comenius', thumb: '/img/avatars/1-small.png' }, public: true, likes: 1, views: 1, liked: false, collections: [] },
                { thumb: '/img/bg/10.jpg', slug: 'book-1', title: 'Digital Book', icon: "ion ion-ios-book", category: 'book', author: { name: 'Comenius', thumb: '/img/avatars/1-small.png' }, public: true, likes: 1, views: 1, liked: false, collections: [] },
                { thumb: '/img/bg/11.jpg', slug: 'sim-1', title: 'Simulation', icon: "ion ion-ios-flask", category: 'experiment', author: { name: 'You', thumb: '/img/avatars/2-small.png' }, public: false, likes: 1, views: 1, liked: false, collections: [{ title: 'Mate5' }] },
                { thumb: '/img/bg/12.jpg', slug: 'quiz-2', title: 'Smart Quizz', icon: "ion ion-ios-musical-notes", category: 'music', author: { name: 'Comenius', thumb: '/img/avatars/1-small.png' }, public: true, likes: 1, views: 1, liked: false, collections: [] }
            ],
        }
    },
    watch: {
        'title': function (old, newValue) {
            $emit('update-title', newValue)
        }
    },
    computed: {
        templates() {
            const templates = this.curTag === 'all'
                ? this.allTemplates
                : this.allTemplates.filter(t => {
                    if (t.category && t.category === this.curTag) return true
                    if (!t.category && this.curTag === 'other') return true
                    return false
                })

            return templates
        }
    },
    methods: {
        init (sortBy) {
            //fetch data sorted by sortBy
        },
        addNewPage (after) {
            if(after=='-1') this.pages.push({ thumb: '/img/bg/blank.jpg' })
            //else add after page
        }
    }
}
</script>