<template>
    <div class="col-sm-6 col-md-6 col-xl-3 mb-4">
        <a :href="`/studio2/activity/`+object.slug" class="img-thumbnail rounded-lg2 overflow-hidden img-thumbnail-shadow2">
            <span v-if="object.thumb" class="img-thumbnail-overlay img-thumbnail-overlay3 bg-t-gradient opacity-75a"></span>
            <span v-else class="img-thumbnail-overlay img-thumbnail-overlay3  opacity-75a"></span>

            <span v-if="object.thumb" class="img-thumbnail-overlay2 opacity-25"></span>
            <span v-if="object.category" :title="object.category" :class="'bg-c-'+object.category" class="img-thumbnail-content-top img-thumbnail-content-top2 display-5 text-white rounded opacity-75 p-1 px-2 text-lg">
                <i :class="object.icon"></i>
            </span>

            <span :class="object.thumb?'text-white':'text-dark'" class="img-thumbnail-content-title img-thumbnail-content-title-flex display-6 text-left w-full p-3 flex">
                
                <h3 class="text-base inline">{{ object.title }}</h3>
                <div class="ml-auto mr-0 inline text-right">
                    <span class="font-bold ml-1 text-md text-right bg-white rounded-md py-1 px-2">
                        <a href="javascript:void(0)" class="text-gray-500 icon-hover"><i class="ion ion-md-folder"></i></a>
                    </span>
                    <span class="font-bold ml-1 text-md text-right bg-white rounded-md py-1 px-2">
                        <a href="javascript:void(0)" class="text-gray-500 icon-hover"><i class="ion ion-md-heart"></i></a>
                    </span>
                </div>
            </span>

            <span :class="object.thumb?'text-white':'text-dark'" class="img-thumbnail-content display-4 text-white">
                <i class="ion ion-ios-eye"></i>
            </span>
            <img v-if="object.thumb" :src="object.thumb" alt class="img-fluid">
            <img v-else src="/img/bg/blank.jpg" alt class="img-fluid">
        </a>
        <div class="pt-2 flex">
            <a :href="`/studio2/activity/`+object.slug" class="text-dark initcap">
                <img v-if="object.author.thumb" :src="object.author.thumb" alt class="img-fluid ui-w-20 rounded-circle inline"/>
                <img v-else src="/img/placeholder2.png" alt class="img-fluid ui-w-20 rounded-circle inline"/>
                <span class="font-bold ml-1 text-md">{{ object.author.name }}</span>
            </a>
            <div class="ml-auto mr-0 inline text-right">
                <span class="font-bold ml-1 text-md text-right">
                    <a href="javascript:void(0)" class="text-dark icon-hover"><i class="ion ion-md-heart"></i> {{ object.likes }}</a>
                </span>
                <span class="font-bold ml-1 text-md text-right">
                    <a href="javascript:void(0)" class="text-dark icon-hover"><i class="ion ion-md-eye"></i>  {{ object.views }}</a>
                </span>
            </div>
        </div>
        
    </div>
</template>

<style>
.img-thumbnail-content-top2 {
    position: absolute;
    top: 13% !important;
    left: 9% !important;
}
a.icon-hover:hover {
    color: #E76F51 !important;
}
.img-thumbnail-content-title-flex
{
    display: flex !important;
}
</style>
<script>

export default {
  name: 'ui2-activity-card',
  props: ['object'],
  data () {
      return {
        
      }
  },
  mounted () {
    
  },

  beforeDestroy () {
    
  },

  methods: {
    method () {
      
    }
  }
}
</script>