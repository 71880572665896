<template>
    <div class="p-10">

        <h4 class="d-flex flex-wrap justify-content-between align-items-center w-100 font-weight-bold pt-2a mb-4a mb-0 -ml-5a">
            <div class="col-5 col-md1 px-0 pb-2 ml-0 text-gray-500">Templates</div>
            <!-- Search -->
            <label class="nav-item navbar-text navbar-search-box p-0 px-3 active ml-auto inline text-xs rounded-xl bg-white">
              <i class="ion ion-ios-search navbar-icon align-middle"></i>
              <span class="navbar-search-input pl-2">
                <input type="text" class="form-control navbar-text mx-2" placeholder="Search..." style="width:200px">
              </span>
            </label>
        </h4>


        <div v-if="visible" class="inline text-right ml-auto text-primary text-xs pr-0"> 
            <select v-model="sortBy">
                <option>Popular</option>
                <option>New</option>
                <option>Recomended</option>
                <option>Marketplace</option>
            </select>
        </div>

        <ul class="nav nav-tabs tabs-alt mb-4">
            <li class="nav-item">
                <a class="nav-link" @click.prevent="curTag ='all'" :class="{active: curTag=='all'}" href="#">All</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" @click.prevent="curTag ='simulation'" :class="{active: curTag=='simulation'}" href="#">Simulation</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" @click.prevent="curTag ='book'" :class="{active: curTag=='book'}" href="#">Book</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" @click.prevent="curTag ='experiment'" :class="{active: curTag=='experiment'}" href="#">Experiment</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" @click.prevent="curTag ='music'" :class="{active: curTag=='music'}" href="#">Music</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" @click.prevent="curTag ='other'" :class="{active: curTag=='other'}" href="#">Other</a>
            </li>
        </ul>


        <div class="row mt-10">

            <ui2-activity-card v-for="(a,i) in templates" :key="i" :object="a"/>

        </div>
  
    </div>
</template>

<script>
   import ActivityCard from './Partials/TemplateCard'

   export default {
     name: 'templates',
     components: { 
        'ui2-activity-card': ActivityCard
    },
    mounted () {
      this.init()
    },
    data () {
      return {
        sortBy: 'Popular',
        curTag: 'all',
        allTemplates: [
            {thumb:'', title: 'Blank template', slug:"blank", author: {name: 'Comenius', thumb: '/img/avatars/1-small.png'}, public: true ,likes: 1, views: 1, liked: false, collections: [] },
            {thumb: '/img/bg/9.jpg', slug: 'quiz-1', title: 'Interactive Quizz', icon:"ion ion-ios-cube", category: 'simulation', author: {name: 'Comenius', thumb: '/img/avatars/1-small.png'}, public: true ,likes: 1, views: 1, liked: false, collections: [] },
            {thumb: '/img/bg/10.jpg', slug: 'book-1', title: 'Digital Book', icon:"ion ion-ios-book",  category: 'book', author: {name: 'Comenius', thumb: '/img/avatars/1-small.png'}, public: true ,likes: 1, views: 1, liked: false, collections: [] },
            {thumb: '/img/bg/11.jpg', slug: 'sim-1', title: 'Simulation', icon:"ion ion-ios-flask",  category: 'experiment', author: {name: 'You', thumb: '/img/avatars/2-small.png'}, public: false ,likes: 1, views: 1, liked: false, collections: [{title:'Mate5'}] },
            {thumb: '/img/bg/12.jpg', slug: 'quiz-2', title: 'Smart Quizz', icon:"ion ion-ios-musical-notes", category: 'music', author: {name: 'Comenius', thumb: '/img/avatars/1-small.png'}, public: true ,likes: 1, views: 1, liked: false, collections: [] }
        ],
      }
    },
    computed: {
        templates () {
            const templates = this.curTag === 'all'
                ? this.allTemplates
                : this.allTemplates.filter(t => {
                    if(t.category && t.category === this.curTag) return true
                    if(!t.category && this.curTag === 'other') return true
                    return false
                })

            return templates
        }
    }
  }
</script>