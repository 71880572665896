<template>
    <div class="col-sm-3 col-md-3 col-xl-2 mb-4">
        <a :href="`/studio2/new/`+object.slug" class="img-thumbnail rounded-lg2 overflow-hidden img-thumbnail-shadow2">
            <span v-if="object.thumb" class="img-thumbnail-overlay img-thumbnail-overlay3 bg-t-gradient opacity-75a"></span>
            <span v-else class="img-thumbnail-overlay img-thumbnail-overlay3  opacity-75a"></span>

            <span v-if="object.thumb" class="img-thumbnail-overlay2 opacity-25"></span>
            <span v-if="object.category" :class="'bg-c-'+object.category" class="img-thumbnail-content-top display-5 text-white rounded opacity-75 p-1">
                <i :class="object.icon"></i>
            </span>

            <span :class="object.thumb?'text-white':'text-dark'" class="img-thumbnail-content-title display-6 text-center w-full">
                <h3 class="text-base">Use</h3>
            </span>

            <span :class="object.thumb?'text-white':'text-dark'" class="img-thumbnail-content display-4 text-white">
                <i class="ion ion-ios-add-circle-outline"></i>
            </span>
            <img v-if="object.thumb" :src="object.thumb" alt class="img-fluid">
            <img v-else src="/img/bg/blank.jpg" alt class="img-fluid">
        </a>
        <div class="text-center m-auto pt-2">
            <a :href="`/studio2/new/`+object.slug" class="text-dark initcap"><span>{{ object.title }}</span></a>
        </div>
        
    </div>
</template>


<script>

export default {
  name: 'ui2-template-card',
  props: ['object'],
  data () {
      return {
        
      }
  },
  mounted () {
    
  },

  beforeDestroy () {
    
  },

  methods: {
    method () {
      
    }
  }
}
</script>