import Layout1 from '@/layout/Layout1'

export default [{
  path: '/admin',
  component: Layout1,
  children: [{
    path: 'users',
    component: () => import('@components/admin/user/List')
  }, {
    path: 'users/form',
    component: () => import('@components/admin/user/Form')
  }, {
    path: 'users/form/:id',
    component: () => import('@components/admin/user/Form')
  }]
}]
