<template>
  <div id="recent-container" class="pb-3 mb-2 border-b w-100 ml-1">
      <a href="javascript:void(0)" 
      :class="visible ? null : 'collapsed'"
      :aria-expanded="visible ? 'true' : 'false'"
      aria-controls="recent"
       class="d-flex justify-content-between text-body">

          <h4 class="d-flex flex-wrap justify-content-between1 align-items-center w-100 font-weight-bold pt-2a mb-4a mb-0 -ml-5">
              <div class="collapse-icon" v-b-toggle.recent data-toggle="collapse"></div>
              
              <div :class="visible ? 'col-10' : 'col-12a'" class="col-md1 px-0 pb-2 ml-0 text-gray-500" v-b-toggle.recent data-toggle="collapse">Discover</div>

              
              <div v-if="visible" class="inline text-right ml-auto text-primary text-xs pr-0"> 
                    <select v-model="sortBy">
                        <option>Popular</option>
                        <option>New</option>
                        <option>Recomended</option>
                        <option>Marketplace</option>
                    </select>
                </div>
                <div class="inline text-right ml-auto text-primary text-xs">More</div>
              
          </h4>
      </a>

      <b-collapse id="recent" visible v-model="visible" accordion="recent">
          <div class="row mt-10">

              <ui2-activity-card v-for="(a,i) in recent" :key="i" :object="a"/>
              
          </div>
          
      </b-collapse>

  </div>
</template>

<script>
import ActivityCard from './ActivityCard'

export default {
name: 'ui2-recent',
components: {
  'ui2-activity-card': ActivityCard
},
data () {
    return {
      visible: true,
      sortBy: 'Popular',
      recent: [
          {thumb:'/img/bg/13.jpg', title: 'Presentation', icon:"ion ion-ios-easel", category: 'presentation', slug:"I6aAsNA", author: {name: 'Comenius', thumb: '/img/avatars/1-small.png'}, public: true ,likes: 1, views: 1, liked: false, collections: [] },
          {thumb: '/img/bg/14.jpg', slug: 'SA&BAquiz-1', title: 'Interactive Quizz', icon:"ion ion-ios-cube", category: 'simulation', author: {name: 'Comenius', thumb: '/img/avatars/1-small.png'}, public: true ,likes: 1, views: 1, liked: false, collections: [] },
          {thumb: '/img/bg/15.jpg', slug: 'SHA=6sbook-1', title: 'Digital Book', icon:"ion ion-ios-book",  category: 'book', author: {name: 'Comenius', thumb: '/img/avatars/1-small.png'}, public: true ,likes: 1, views: 1, liked: false, collections: [] },
          {thumb: '/img/bg/16.jpg', slug: 'OSU4ssim-1', title: 'Simulation', icon:"ion ion-ios-flask",  category: 'experiment', author: {name: 'You', thumb: '/img/avatars/2-small.png'}, public: false ,likes: 1, views: 1, liked: false, collections: [{title:'Mate5'}] },
          {thumb: '/img/bg/1.jpg', slug: 'OSU4ssim-1', title: 'Simulation', icon:"ion ion-ios-flask",  category: 'experiment', author: {name: 'You', thumb: '/img/avatars/2-small.png'}, public: false ,likes: 1, views: 1, liked: false, collections: [{title:'Mate5'}] },
          {thumb: '/img/bg/2.jpg', slug: 'OSU4ssim-1', title: 'Simulation', icon:"ion ion-ios-flask",  category: 'experiment', author: {name: 'You', thumb: '/img/avatars/2-small.png'}, public: false ,likes: 1, views: 1, liked: false, collections: [{title:'Mate5'}] },
          {thumb: '/img/bg/3.jpg', slug: 'OSU4ssim-1', title: 'Simulation', icon:"ion ion-ios-flask",  category: 'experiment', author: {name: 'You', thumb: '/img/avatars/2-small.png'}, public: false ,likes: 1, views: 1, liked: false, collections: [{title:'Mate5'}] },
          {thumb: '/img/bg/4.jpg', slug: 'OSU4ssim-1', title: 'Simulation', icon:"ion ion-ios-flask",  category: 'experiment', author: {name: 'You', thumb: '/img/avatars/2-small.png'}, public: false ,likes: 1, views: 1, liked: false, collections: [{title:'Mate5'}] },
          {thumb: '/img/bg/5.jpg', slug: 'OSU4ssim-1', title: 'Simulation', icon:"ion ion-ios-flask",  category: 'experiment', author: {name: 'You', thumb: '/img/avatars/2-small.png'}, public: false ,likes: 1, views: 1, liked: false, collections: [{title:'Mate5'}] },
          {thumb: '/img/bg/6.jpg', slug: 'OSU4ssim-1', title: 'Simulation', icon:"ion ion-ios-flask",  category: 'experiment', author: {name: 'You', thumb: '/img/avatars/2-small.png'}, public: false ,likes: 1, views: 1, liked: false, collections: [{title:'Mate5'}] },
          {thumb: '/img/bg/7.jpg', slug: 'OSU4ssim-1', title: 'Simulation', icon:"ion ion-ios-flask",  category: 'experiment', author: {name: 'You', thumb: '/img/avatars/2-small.png'}, public: false ,likes: 1, views: 1, liked: false, collections: [{title:'Mate5'}] },
          {thumb: '/img/bg/12.jpg', slug: 'OXNAH52quiz-2', title: 'Smart Quizz', icon:"ion ion-ios-musical-notes", category: 'music', author: {name: 'Comenius', thumb: '/img/avatars/1-small.png'}, public: true ,likes: 1, views: 1, liked: false, collections: [] }
      ]
    }
},

watch: {
  sortBy(old, newValue) {
      this.init(newValue)
  }
},

created () {
  this.init(newValue)
},

methods: {
  init (sortBy) {
    //fetch data sorted by sortBy
  }
}
}
</script>