<template>
    <div class="p-10">

      <ui2-activity-templates page-size="5"/>
  
      <ui2-recent-activity page-size="5" scoll="true" grid="false"/>

      <ui2-discover page-size="15" scoll="true" grid="true"/>
  
    </div>
</template>

<style>
.collapse-icon::after {
    transform: rotate(-135deg);
    margin-top: -0.4rem; 
}
[aria-expanded="true"] .collapse-icon::after {
    transform: rotate(-45deg);
    margin-top: -0.5rem;
}
.collapse-icon {
    margin-right: 10px;
}
</style>
<style>

.img-thumbnail-shadow2 {
    /* box-shadow: 0 5px 20px rgb(24 28 33 / 40%); */
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.5)
}

.img-thumbnail-overlay2 {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
    display: block;
    transition: all .2s ease-in-out;
    background: rgb(38, 70, 83);
}

.rounded-lg2 {
    border-radius: 0.5rem !important;
}
/* badge background colors */
.bg-c-simulation { background: rgb(42, 157, 143);}
.bg-c-book { background: rgb(244, 162, 97);}
.bg-c-presentation { background: rgb(38, 70, 83);}
.bg-c-experiment { background: rgb(38, 70, 83);}
.bg-c-music { background: rgb(231, 111, 81);}

.bg-primary-dark {
    background: rgb(38, 70, 83);
    /* background: linear-gradient(0deg, rgba(38, 70, 83,1) 0%, rgba(38, 70, 83,0) 100%); */
}
.bg-t-gradient {
    background: rgb(38, 70, 83);
    background: linear-gradient(0deg, rgba(38, 70, 83,1) 0%, rgba(38, 70, 83,0) 100%);
}
.bg-t-gradient2 {
    background: rgb(233,196,106);
    background: linear-gradient(0deg, rgba(233,196,106,1) 0%, rgba(233,196,106,0) 100%);
}
.img-thumbnail-overlay3
{
    top: 0%;
    height: 100%;
}

.img-thumbnail-content-top
{
    position: absolute;
    top: 8%;
    left: 15%;
    z-index: 3;
    display: block;
    opacity: 1;
    transition: all .2s ease-in-out;
    transform: translate(-50%, -50%);
}

.img-thumbnail-content-title
{
    position: absolute;
    top: 70%;
    left: 0%;
    z-index: 3;
    display: block;
    opacity: 0;
    transition: all .2s ease-in-out;
    /* transform: translate(-50%, -50%); */
}

.img-thumbnail:hover .img-thumbnail-content-title {
    opacity: 1;
}

</style>

<script>
   import Templates from './Partials/Templates.vue'
   import Recent from './Partials/Recent.vue'
   import Discover from './Partials/Discover.vue'

   export default {
     name: 'home',
     components: { 
      'ui2-activity-templates': Templates,
      'ui2-recent-activity': Recent, 
      'ui2-discover': Discover 
    },
    mounted () {
      this.init()
    },
    data () {
      return {}
    }
  }
</script>