<template>
    <div id="templates-container" class="pb-3 mb-2 border-b w-100 ml-1">
        <a href="javascript:void(0)" 
        :class="visible ? null : 'collapsed'"
        :aria-expanded="visible ? 'true' : 'false'"
        aria-controls="templates"
         class="d-flex justify-content-between text-body">

            <h4 class="d-flex flex-wrap justify-content-between1 align-items-center w-100 font-weight-bold pt-2a mb-4a mb-0 -ml-5">
                <div class="collapse-icon" v-b-toggle.templates data-toggle="collapse"></div>
                
                <div :class="visible ? 'col-10' : 'col-12a'" class="col-md1 px-0 pb-2 ml-0 text-gray-500" v-b-toggle.templates data-toggle="collapse">Templates</div>

                <div v-if="!visible" class="inline text-left text-primary text-sm pl-3 pb-1"> 
                    <button>
                        <i class="ion ion-ios-add-circle-outline"></i> Blank template
                    </button>
                </div>
                <div v-if="visible" class="inline text-right ml-auto text-primary text-xs pr-0"> 
                    <select v-model="sortBy">
                        <option>Popular</option>
                        <option>New</option>
                        <option>Recomended</option>
                        <option>Marketplace</option>
                    </select>
                </div>
                <a href="/studio2/new" class="inline text-right ml-auto text-primary text-xs">More templates</a>
            </h4>
        </a>

        <b-collapse id="templates" visible v-model="visible" accordion="templates">
            <div class="row mt-10">

                <ui2-activity-card v-for="(a,i) in templates" :key="i" :object="a"/>
                
            </div>
            
        </b-collapse>

    </div>
</template>

<script>
import ActivityCard from './TemplateCard'

export default {
  name: 'ui2-activity-templates',
  components: {
    'ui2-activity-card': ActivityCard
  },
  data () {
      return {
        visible: true,
        sortBy: 'Popular',
        templates: [
            {thumb:'', title: 'Blank template', slug:"blank", author: {name: 'Comenius', thumb: '/img/avatars/1-small.png'}, public: true ,likes: 1, views: 1, liked: false, collections: [] },
            {thumb: '/img/bg/9.jpg', slug: 'quiz-1', title: 'Interactive Quizz', icon:"ion ion-ios-cube", category: 'simulation', author: {name: 'Comenius', thumb: '/img/avatars/1-small.png'}, public: true ,likes: 1, views: 1, liked: false, collections: [] },
            {thumb: '/img/bg/10.jpg', slug: 'book-1', title: 'Digital Book', icon:"ion ion-ios-book",  category: 'book', author: {name: 'Comenius', thumb: '/img/avatars/1-small.png'}, public: true ,likes: 1, views: 1, liked: false, collections: [] },
            {thumb: '/img/bg/11.jpg', slug: 'sim-1', title: 'Simulation', icon:"ion ion-ios-flask",  category: 'experiment', author: {name: 'You', thumb: '/img/avatars/2-small.png'}, public: false ,likes: 1, views: 1, liked: false, collections: [{title:'Mate5'}] },
            {thumb: '/img/bg/12.jpg', slug: 'quiz-2', title: 'Smart Quizz', icon:"ion ion-ios-musical-notes", category: 'music', author: {name: 'Comenius', thumb: '/img/avatars/1-small.png'}, public: true ,likes: 1, views: 1, liked: false, collections: [] }
        ],
        icons: {
            'premium': 'ion ion-ios-star',
            'experiment': 'ion ion-ios-flask',
            'simulation': 'ion ion-ios-cube',
            'video': 'ion ion-ios-film',
            'art': 'ion ion-ios-color-palette',
            'book': 'ion ion-ios-book',
            'think': 'ion ion-ios-bulb',
            'logic': 'ion ion-ios-calculator',
            'social': 'ion ion-ios-chatbubbles',
            'speed': 'ion ion-ios-clock',
            'music': 'ion ion-ios-musical-notes',
            'earth': 'ion ion-ios-planet'
        }
      }
  },

  watch: {
    sortBy(old, newValue) {
        this.init(newValue)
    }
  },

  created () {
    this.init(newValue)
  },

  methods: {
    init (sortBy) {
      //fetch data sorted by sortBy
    }
  }
}
</script>