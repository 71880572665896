import LayoutMenu from '@/layout/LayoutApps'

export default [{
  path: '/apps',
  component: LayoutMenu,
  children: [{
    path: 'apps',
    component: () => import('@components/apps/Apps')
  }, {
    path: 'createTask',
    component: () => import('@components/apps/CreateTask')
  }, {
    path: 'taskList',
    component: () => import('@components/apps/TaskList')
  }, {
    path: 'editTask/:id?',
    component: () => import('@components/apps/CreateTask')
  }]
}]
