<template>
  <sidenav :orientation="orientation" :class="curClasses">

    <b-navbar-brand  class="align-items-lg-center m-auto mr-lg-4a">
      <!-- <img src="/img/logo.png" class="logo" style="max-height:30px;"> -->
      <a style="color: #264653;" class="nav-item nav-link px-0 ml-2 ml-lg-0" href="javascript:void(0)" @click="toggleSidenav">
        <i class="ion ion-md-menu text-large align-middle" />
      </a>
    </b-navbar-brand>
    <!-- Inner -->
    <div class="sidenav-inner">

      <sidenav-router-link icon="ion ion-ios-home" to="/studio2/home" :exact="true">Home</sidenav-router-link>
      <sidenav-router-link icon="ion ion-ios-add-circle-outline" to="/studio2/new" :exact="true">New</sidenav-router-link>
      <sidenav-router-link icon="ion ion-ios-rocket" to="/studio2/activities" :exact="true">Activitati</sidenav-router-link>
      <sidenav-router-link icon="ion ion-ios-contacts" to="/friends" :exact="true">Prieteni</sidenav-router-link>
      <sidenav-router-link icon="ion ion-ios-body" to="/avatar" :exact="true">Avatar</sidenav-router-link>
      <sidenav-router-link icon="ion ion-ios-finger-print" to="/profile" :exact="true">Profil</sidenav-router-link>
      <sidenav-router-link icon="ion ion-md-calendar" to="/events" :exact="true">Evenimente</sidenav-router-link>
      <sidenav-router-link icon="ion ion-md-radio" to="/news" :exact="true">Noutati</sidenav-router-link>
      <sidenav-router-link icon="ion ion-md-notifications" to="/notifications" :exact="true">Notificari</sidenav-router-link>

      <div v-if="role=='admin'||role=='superadmin'">
        <sidenav-divider class="mb-1"/>
        <sidenav-header class="small font-weight-semibold">ADMIN</sidenav-header>
        <sidenav-router-link icon="ion ion-md-calendar" to="/admin/events" :exact="true">Evenimente</sidenav-router-link>
        <sidenav-router-link icon="ion ion-md-radio" to="/admin/news" :exact="true">Noutati</sidenav-router-link>
        <sidenav-router-link icon="ion ion-md-add-circle-outline" to="/admin/extensions" :exact="true">Extensii interactive</sidenav-router-link>
        <sidenav-router-link icon="ion ion-md-aperture" to="/admin/ar-elements" :exact="true">Realitate augmentata</sidenav-router-link>
        <!-- <sidenav-router-link icon="ion ion-md-eye-off" to="/admin/moderator" :exact="true">Moderare</sidenav-router-link> -->
        <sidenav-router-link icon="ion ion-md-contacts" to="/admin/users" :exact="true">Utilizatori</sidenav-router-link>
      </div>

      <div v-if="role=='superadmin'">
        <sidenav-divider class="mb-1"/>
        <sidenav-header class="small font-weight-semibold">SUPERADMIN</sidenav-header>
        <sidenav-router-link icon="ion ion-md-apps" to="#" :exact="true">Biblioteci</sidenav-router-link>
        <sidenav-router-link icon="ion ion-md-apps" to="#" :exact="true">Obiecte virtuale</sidenav-router-link>
      </div>

    </div>
  </sidenav>
</template>

<style>
.navbar-brand
{
  margin-bottom: 1rem !important;
    margin-top: 0.5rem !important;
}
.sidenav-vertical .sidenav-icon {
    width: 2.5rem;
}
.sidenav-icon {
    font-size: 2.5rem;
    font-size: 1.5rem;
    margin-right: 0;
}
.sidenav-item .sidenav-link
{
  display: block;
  text-align: center;
}
.sidenav-vertical, .sidenav-vertical .sidenav-block, .sidenav-vertical .sidenav-inner > .sidenav-item, .sidenav-vertical .sidenav-inner > .sidenav-header {
    width: 7rem;
}
.sidenav-vertical .sidenav-item .sidenav-link, .sidenav-vertical .sidenav-header, .sidenav-vertical .sidenav-block {
    padding: 0.75rem 0rem;
}
.sidenav.bg-white .sidenav-item.active > .sidenav-link:not(.sidenav-toggle) {
    /* background-color: rgba(200,200,200,0.3);
    margin: 1rem 1rem;
    padding: 0.5rem 0;
    border-radius: 1rem; */

    background-color: rgba(233, 196, 106,0.2);
    margin: 1rem 0.8rem !important;
    padding: 1rem 0 0.7rem 0 !important;
    border-radius: 1rem;
    color: #E76F51;
}

.sidenav.bg-white .sidenav-link:hover, .sidenav.bg-white .sidenav-link:focus, .sidenav.bg-white .sidenav-horizontal-prev:hover, .sidenav.bg-white .sidenav-horizontal-prev:focus, .sidenav.bg-white .sidenav-horizontal-next:hover, .sidenav.bg-white .sidenav-horizontal-next:focus {
    color: #4E5155;
    margin: 0rem 0.8rem !important;
    /* padding: 1rem 0 0.7rem 0 !important; */
    border-radius: 1rem;
    background-color: rgba(38, 70, 83,0.2);
}

.layout-collapsed:not(.layout-sidenav-hover):not(.layout-offcanvas):not(.layout-fixed-offcanvas) .layout-sidenav .sidenav-vertical .sidenav-inner > .sidenav-item, .layout-collapsed:not(.layout-sidenav-hover):not(.layout-offcanvas):not(.layout-fixed-offcanvas) .layout-sidenav.sidenav-vertical .sidenav-inner > .sidenav-item {
    width: 9rem;
}

.layout-collapsed:not(.layout-sidenav-hover):not(.layout-offcanvas):not(.layout-fixed-offcanvas) .layout-sidenav .sidenav-vertical .sidenav-inner > .sidenav-item > .sidenav-link, .layout-collapsed:not(.layout-sidenav-hover):not(.layout-offcanvas):not(.layout-fixed-offcanvas) .layout-sidenav.sidenav-vertical .sidenav-inner > .sidenav-item > .sidenav-link {
    padding-left: 0rem;
}

.layout-collapsed .sidenav-item .sidenav-link > :not(.sidenav-icon)
{
  display: none;
}

.layout-sidenav-hover.layout-collapsed .layout-sidenav {
    margin-right: 0rem;
}

.layout-sidenav-hover.layout-collapsed .sidenav-vertical {
/* .layout-sidenav-hover.layout-collapsed .sidenav-vertical .sidenav-block, */
/* .layout-sidenav-hover.layout-collapsed .sidenav-vertical .sidenav-inner > .sidenav-item, */
/* .layout-sidenav-hover.layout-collapsed .sidenav-vertical .sidenav-inner > .sidenav-header { */
    width: 4.375rem;
}

.layout-sidenav-hover.layout-collapsed .sidenav-vertical .sidenav-icon
{
  margin-left: -2.375rem;
}

</style>
<script>
import { Sidenav, SidenavLink, SidenavRouterLink, SidenavMenu, SidenavHeader, SidenavBlock, SidenavDivider } from '@vendor/libs/sidenav'

export default {
  name: 'app-layout-sidenav',
  components: {
    /* eslint-disable vue/no-unused-components */
    Sidenav,
    SidenavLink,
    SidenavRouterLink,
    SidenavMenu,
    SidenavHeader,
    SidenavBlock,
    SidenavDivider
    /* eslint-enable vue/no-unused-components */
  },

  props: {
    orientation: {
      type: String,
      default: 'vertical'
    }
  },

  data: () => ({
    role: 'user'
  }),

  created () {
    this.$http.get('/role').then(response => {
      this.role = response.data
    }).catch(e => {
      console.log(e)
    })
  },

  computed: {
    curClasses () {
      let bg = this.layoutSidenavBg

      if (this.orientation === 'horizontal' && (bg.indexOf(' sidenav-dark') !== -1 || bg.indexOf(' sidenav-light') !== -1)) {
        bg = bg
          .replace(' sidenav-dark', '')
          .replace(' sidenav-light', '')
          .replace('-darker', '')
          .replace('-dark', '')
      }

      return `bg-${bg} ` + (
        this.orientation !== 'horizontal'
          ? 'layout-sidenav'
          : 'layout-sidenav-horizontal container-p-x flex-grow-0'
      )
    }
  },

  methods: {
    isMenuActive (url) {
      return this.$route.path.indexOf(url) === 0
    },

    isMenuOpen (url) {
      return this.$route.path.indexOf(url) === 0 && this.orientation !== 'horizontal'
    },

    toggleSidenav () {
      this.layoutHelpers.toggleCollapsed()
    }
  }
}
</script>
