import Layout1 from '@/layout/Layout1'

export default [{
  path: '/',
  component: Layout1,
  children: [
    {
      path: 'dashboard',
      component: () => import('@components/user/Dashboard')
    },
    {
      path: 'friends',
      component: () => import('@components/user/Friends')
    },
    {
      path: 'profile',
      component: () => import('@components/user/Profile')
    },
    {
      path: 'notifications',
      component: () => import('@components/user/Notifications')
    }
  ]
}]
