<template>
  <div class="router-transitions">

    <div class="page-loader">
      <div class="bg-primary"></div>
    </div>

    <!-- Navbar -->
    <nav class="landing-navbar navbar navbar-expand-lg navbar-light fixed-top pt-lg-4">
      <div class="container-fluid px-3">
        <a href="/home" class="navbar-brand text-big font-weight-bolder line-height-1 text-expanded py-3">
          <img src="/img/logo.png" class="logo" style="max-height:50px;">
        </a>

        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#landing-navbar-collapse">
          <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse" id="landing-navbar-collapse">
          <div class="navbar-nav align-items-lg-center ml-auto">
            <!-- <a class="anchor-link nav-item nav-link" href="/home">Home</a> -->
            <div class="nav-item py-3 py-lg-0 ml-lg-4">
              <router-link to="/auth/login" class="anchor-link btn btn-primary rounded-pill text-expanded"><small>LOGIN</small></router-link>
            </div>
          </div>
        </div>
      </div>
    </nav>
    <!-- / Navbar -->

    <router-view></router-view>

<!-- Block -->
  <div id="subscribe" class="bg-secondary text-white py-5">
    <div class="container px-3">
      <div class="row align-items-center">

        <div class="font-secondary col-md-5 col-lg-4 text-xlarge font-weight-light">
          Aboneaza-te la Newsletter
        </div>

        <div class="col-md-7 col-lg-8 mt-3 mt-md-0">
          <div class="input-group">
            <input type="email" class="form-control form-control-inverted form-control-lg bg-lighter border-0 px-3" placeholder="E-mail">
            <span class="input-group-append">
                <button type="submit" class="btn btn-primary btn-lg">Ma abonez</button>
              </span>
          </div>
        </div>

      </div>
    </div>
  </div>
  <!-- / Block -->
  <!-- Footer -->
  <nav class="footer bg-white pt-4">
    <div class="container text-center py-4">
      <div class="pb-3">
        <span class="align-top">©</span>
        <a href="#" class="footer-text text-big font-weight-bolder text-expanded">L2L</a>
      </div>
      <hr class="mb-4">
      <div class="row">
        <div class="col-6 col-sm pb-6">
          <div class="footer-text font-secondary text-tiny font-weight-bold mb-3">DESPRE</div>
          <a href="https://lib2life.ro/" class="footer-link d-block small pb-2">Proiect</a>
          <a href="https://lib2life.ro/cyber-physical-library/" class="footer-link d-block small pb-2">Subproiect</a>
          <a href="https://lib2life.ro/noutati/" class="footer-link d-block small pb-2">Noutati</a>
        </div>
        <div class="col-6 col-sm pb-6">
          <div class="footer-text font-secondary text-tiny font-weight-bold mb-3">PARTNERI</div>
          <a href="https://lib2life.ro/consortiu/" class="footer-link d-block small pb-2">Consortiu</a>
          <a href="#" class="footer-link d-block small pb-2">UPB</a>
          <a href="#" class="footer-link d-block small pb-2">BCU</a>
        </div>
        <div class="col-6 col-sm pb-6">
          <div class="footer-text font-secondary text-tiny font-weight-bold mb-3">CONNECT</div>

          <a href="#" class="footer-link d-block small pb-2">
          <i class="ion ion-logo-facebook text-facebook"></i> &nbsp;Facebook</a>
          <a href="#" class="footer-link d-block small pb-2">
          <i class="ion ion-logo-instagram text-instagram"></i> &nbsp;Instagram</a>
        </div>
      </div>
    </div>
  </nav>
  <!-- / Footer -->
  </div>
</template>

<script>
export default {
  name: 'app-layout-blank'
}
</script>
