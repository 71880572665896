<template>
  <sidenav :orientation="orientation" :class="curClasses">

    <!-- Inner -->
    <div class="sidenav-inner" :class="{ 'py-1': orientation !== 'horizontal' }">

      <sidenav-router-link icon="ion ion-ios-desktop" to="/dashboard" :exact="true">Dashboard</sidenav-router-link>
      <sidenav-router-link icon="ion ion-ios-business" to="/libraries" :exact="true">Biblioteci</sidenav-router-link>
      <sidenav-router-link icon="ion ion-ios-contacts" to="/friends" :exact="true">Prieteni</sidenav-router-link>
      <sidenav-router-link icon="ion ion-ios-body" to="/avatar" :exact="true">Avatar</sidenav-router-link>
      <sidenav-router-link icon="ion ion-ios-finger-print" to="/profile" :exact="true">Profil</sidenav-router-link>
      <sidenav-router-link icon="ion ion-md-calendar" to="/events" :exact="true">Evenimente</sidenav-router-link>
      <sidenav-router-link icon="ion ion-md-radio" to="/news" :exact="true">Noutati</sidenav-router-link>
      <sidenav-router-link icon="ion ion-md-notifications" to="/notifications" :exact="true">Notificari</sidenav-router-link>

      <div v-if="role=='admin'||role=='superadmin'">
        <sidenav-divider class="mb-1"/>
        <sidenav-header class="small font-weight-semibold">ADMIN</sidenav-header>
        <sidenav-router-link icon="ion ion-md-calendar" to="/admin/events" :exact="true">Evenimente</sidenav-router-link>
        <sidenav-router-link icon="ion ion-md-radio" to="/admin/news" :exact="true">Noutati</sidenav-router-link>
        <sidenav-router-link icon="ion ion-md-add-circle-outline" to="/admin/extensions" :exact="true">Extensii interactive</sidenav-router-link>
        <sidenav-router-link icon="ion ion-md-aperture" to="/admin/ar-elements" :exact="true">Realitate augmentata</sidenav-router-link>
        <!-- <sidenav-router-link icon="ion ion-md-eye-off" to="/admin/moderator" :exact="true">Moderare</sidenav-router-link> -->
        <sidenav-router-link icon="ion ion-md-contacts" to="/admin/users" :exact="true">Utilizatori</sidenav-router-link>
      </div>

      <div v-if="role=='superadmin'">
        <sidenav-divider class="mb-1"/>
        <sidenav-header class="small font-weight-semibold">SUPERADMIN</sidenav-header>
        <sidenav-router-link icon="ion ion-md-apps" to="#" :exact="true">Biblioteci</sidenav-router-link>
        <sidenav-router-link icon="ion ion-md-apps" to="#" :exact="true">Obiecte virtuale</sidenav-router-link>
      </div>

    </div>
  </sidenav>
</template>

<script>
import { Sidenav, SidenavLink, SidenavRouterLink, SidenavMenu, SidenavHeader, SidenavBlock, SidenavDivider } from '@vendor/libs/sidenav'

export default {
  name: 'app-layout-sidenav',
  components: {
    /* eslint-disable vue/no-unused-components */
    Sidenav,
    SidenavLink,
    SidenavRouterLink,
    SidenavMenu,
    SidenavHeader,
    SidenavBlock,
    SidenavDivider
    /* eslint-enable vue/no-unused-components */
  },

  props: {
    orientation: {
      type: String,
      default: 'vertical'
    }
  },

  data: () => ({
    role: 'user'
  }),

  created () {
    this.$http.get('/role').then(response => {
      this.role = response.data
    }).catch(e => {
      console.log(e)
    })
  },

  computed: {
    curClasses () {
      let bg = this.layoutSidenavBg

      if (this.orientation === 'horizontal' && (bg.indexOf(' sidenav-dark') !== -1 || bg.indexOf(' sidenav-light') !== -1)) {
        bg = bg
          .replace(' sidenav-dark', '')
          .replace(' sidenav-light', '')
          .replace('-darker', '')
          .replace('-dark', '')
      }

      return `bg-${bg} ` + (
        this.orientation !== 'horizontal'
          ? 'layout-sidenav'
          : 'layout-sidenav-horizontal container-p-x flex-grow-0'
      )
    }
  },

  methods: {
    isMenuActive (url) {
      return this.$route.path.indexOf(url) === 0
    },

    isMenuOpen (url) {
      return this.$route.path.indexOf(url) === 0 && this.orientation !== 'horizontal'
    },

    toggleSidenav () {
      this.layoutHelpers.toggleCollapsed()
    }
  }
}
</script>
