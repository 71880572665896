import Layout from '@/layout/UI2'
import Dashboard from '@components/ui2/Dashboard'
import Activity from '@components/ui2/Activity'
import AllTemplates from '@components/ui2/AllTemplates'
import MyActivities from '@components/ui2/MyActivities'

export default [{
  path: '/studio2',
  component: Layout,
  children: [{
    path: 'home',
    component: Dashboard
  }, {
    path: 'new',
    component: AllTemplates
  }, {
    path: 'new/:template',
    component: Activity
  }, {
    path: 'activity/:id',
    component: Activity
  }, {
    path: 'activities',
    component: MyActivities
  }
  ]
}]
