<template>
    <div class="h-screen h-96a overflow-hidden min-h-screen1 -mt-14">
        <ui2-activity-toolbar />

        <div class="flex flex-row bg-gray-200">
            <div>
                <b-tabs small class="nav-tabs-top" nav-wrapper-class="nav-sm">
                    <b-tab active >
                        <template #title>
                            <i class="ion ion-ios-albums"></i> Pages
                        </template>
                        <div class="card-body p-0">
                            
                            <div class="flex-col hidden px-3 pl-2i mt-2 space-y-3 sm:flex h-screen90 p-6a overflow-y-scroll scrollbar">
                                <div class="relative" v-for="(p,i) in pages" :key="i">
                                    <div class="absolute -ml-5">{{ i+1 }}</div>
                                    <div class="overflow-hidden rounded-md w-36 h-24 transition duration-200 transform scale-100 bg-white border border-gray-300 shadow-sm scale hover:scale-95">
                                        <img :src="p.thumb" alt="" class="absolute top-0">
                                    </div>
                                </div>
                                <div class="relative">
                                    
                                    <div @click="addNewPage(-1)" class="overflow-hidden rounded-md w-36 h-24 transition duration-200 transform scale-100 bg-white border border-gray-300 shadow-sm scale hover:scale-95">
                                        <span class="text-dark img-thumbnail-content display-4  opacity-100">
                                            <i class="ion ion-ios-add-circle-outline"></i>
                                        </span>
                                    </div>
                                </div>
                
                                <div class="pb-10"></div>
                
                            </div>

                        </div>
                    </b-tab>
                    <b-tab >
                        <template #title>
                            <i class="ion ion-ios-git-merge"></i> Hierarchy
                        </template>
                        <div class="card-body p-0">
                            <div class="flex-col hidden px-3 pl-2i mt-2 space-y-3 sm:flex h-screen90 p-6a overflow-y-scroll scrollbar">
                                <div class="relative">
                                    <div class="overflow-hidden rounded-md w-36 h-24 transition duration-200 transform scale-100 bg-white border border-gray-300 shadow-sm scale hover:scale-95">
                                        Hierarchy<br/>
                                        aaa<br/>
                                        -- aaaaa<br/>
                                        --- aaaa<br/>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </b-tab>

                </b-tabs>
            </div>
            
            <div class="flex flex-col w-full border-l border-r border-gray-300 ml-1 ">
                <div class="h-96a">
                    <b-tabs small class="nav-tabs-top" nav-wrapper-class="nav-sm">
                        <b-tab active >
                            <template #title>
                                <i class="ion ion-ios-albums"></i> Edit
                            </template>
                            <div class="card-body p-0">
                                <div class="flex items-center justify-center flex-grow p-5 mx-auto xl:p-10">
                                    <div
                                        class="h-48 w-96 mx-auto transition duration-200 transform scale-100 bg-white border border-gray-300 shadow-sm scale hover:scale-95">

                                        canvas 
                                    </div>
                                </div>
                            </div>
                        </b-tab>
                    </b-tabs>
                </div>
                
                <div class="p-5 text-gray-600 border-t border-gray-300 cursor-pointer hover:text-gray-800">
                    <p>Click to add notes</p>
                </div>
            </div>
            <ui2-activity-sidebar-right />
        </div>
        <!-- <div
                        class="flex flex-row items-center justify-between p-5 text-sm text-gray-500 bg-white border-t border-gray-300">
                        <div class="inline-flex items-center">
                            <p class="mr-4">Slide 1 of 16</p>
                            <button class="transition duration-200 hover:bg-gray-100 p-2 rounded-lg">
                                <svg class="w-6 h-6 text-gray-500" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                        d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253">
                                    </path>
                                </svg>
                            </button>
                        </div>
                        <button class="transition duration-200 hover:bg-gray-100 p-2 rounded-lg">
                            <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                    d="M8 13v-1m4 1v-3m4 3V8M8 21l4-4 4 4M3 4h18M4 4h16v12a1 1 0 01-1 1H5a1 1 0 01-1-1V4z">
                                </path>
                            </svg>
                        </button>
                    </div> -->

        

    </div>
</template>
<style>
.h-screen90 {
    height: 80vh;
}
.pl-2i {
    padding-left: 2rem !important;
}

.scrollbar::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    /* padding-top: 2px; */
  }

.scrollbar::-webkit-scrollbar-track {
    border-radius: 100vh;
    background: transparent;
}

.scrollbar::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 100vh;
    border: 0px solid #264653;
}

.scrollbar::-webkit-scrollbar-thumb:hover {
    background: #c0a0b9;
}
.tab-content
{
    background: transparent !important;
}
</style>
<script>
import ActivityToolbar from './Partials/ActivityToolbar'
import ActivitySidebarRight from './Partials/ActivitySidebarRight'
// import ActivityThumb from './Partials/ActivityThumb'

export default {
    name: 'templates',
    components: {
        'ui2-activity-toolbar': ActivityToolbar,
        'ui2-activity-sidebar-right': ActivitySidebarRight
    },
    mounted() {
        this.init()
    },
    data() {
        return {
            title: 'Draft',
            pages: [
                { thumb: '/img/bg/1.jpg' },
                { thumb: '/img/bg/2.jpg' },
                { thumb: '/img/bg/3.jpg' },
                { thumb: '/img/bg/4.jpg' },
                { thumb: '/img/bg/5.jpg' },
                { thumb: '/img/bg/6.jpg' },
                { thumb: '/img/bg/7.jpg' },
                { thumb: '/img/bg/8.jpg' },
            ],
            sortBy: 'Popular',
            curTag: 'all',
            allTemplates: [
                { thumb: '', title: 'Blank template', slug: "blank", author: { name: 'Comenius', thumb: '/img/avatars/1-small.png' }, public: true, likes: 1, views: 1, liked: false, collections: [] },
                { thumb: '/img/bg/9.jpg', slug: 'quiz-1', title: 'Interactive Quizz', icon: "ion ion-ios-cube", category: 'simulation', author: { name: 'Comenius', thumb: '/img/avatars/1-small.png' }, public: true, likes: 1, views: 1, liked: false, collections: [] },
                { thumb: '/img/bg/10.jpg', slug: 'book-1', title: 'Digital Book', icon: "ion ion-ios-book", category: 'book', author: { name: 'Comenius', thumb: '/img/avatars/1-small.png' }, public: true, likes: 1, views: 1, liked: false, collections: [] },
                { thumb: '/img/bg/11.jpg', slug: 'sim-1', title: 'Simulation', icon: "ion ion-ios-flask", category: 'experiment', author: { name: 'You', thumb: '/img/avatars/2-small.png' }, public: false, likes: 1, views: 1, liked: false, collections: [{ title: 'Mate5' }] },
                { thumb: '/img/bg/12.jpg', slug: 'quiz-2', title: 'Smart Quizz', icon: "ion ion-ios-musical-notes", category: 'music', author: { name: 'Comenius', thumb: '/img/avatars/1-small.png' }, public: true, likes: 1, views: 1, liked: false, collections: [] }
            ],
        }
    },
    watch: {
        'title': function (old, newValue) {
            $emit('update-title', newValue)
        }
    },
    computed: {
        templates() {
            const templates = this.curTag === 'all'
                ? this.allTemplates
                : this.allTemplates.filter(t => {
                    if (t.category && t.category === this.curTag) return true
                    if (!t.category && this.curTag === 'other') return true
                    return false
                })

            return templates
        }
    },
    methods: {
        init (sortBy) {
            //fetch data sorted by sortBy
        },
        addNewPage (after) {
            if(after=='-1') this.pages.push({ thumb: '/img/bg/blank.jpg' })
            //else add after page
        }
    }
}
</script>